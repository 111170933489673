/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react"
import { findAccountByAccountId, getCustomerAccounts, listBeneficiary } from "../../services/acountServices"
import { connect } from "react-redux"
import { ThemeContext } from "../../../context/ThemeContext"
import { accountNumberLength } from "../../../common/constant"
import { getTransactionBilling, makeAccountTransfer } from "../../services/mobileBankService"
import { FormInput } from "../../../common/jsx/components/FormInput"
import { Link, useNavigate } from "react-router-dom"
import { Nav, Tab } from "react-bootstrap"
import ErrorManager from "../../errors"
import ModalAfterOperation from "../../components/ModalAfterOperation"
import { useTranslation } from "react-i18next"
import { formatAmount } from "../../../utils/utilsFormattedAmount"
import Benefit from "../../../common/images/svg/benefit.svg"

let initialTransactionDetails = {
  accountID: null,
  accountType: "",
  beneficiary_account: null,
  beneficiary_name: "",
  beneficiary_email: "",
  amount: 0,
  memo: "",
  fee_amount: 0,
  tax: 0,
}

const BankTransfer = (props) => {
  const { t } = useTranslation()

  const [beneficiaryOption, setBeneficiaryOption] = useState("LIST")
  const [beneficiaryList, setBeneficiaryList] = useState([])
  const [selectAccount, setSelectedAccount] = useState("")
  const [modalMessage, setModalMessage] = useState("")
  const [status, setStatus] = useState("")
  const [errorMsg, setErrorMsg] = useState({})
  const { setShowLoading, showLoading, setVerifySecureQuestions, setVerifyCallback, setVerifyOtp, setOtpVerificationCallBack } = useContext(ThemeContext)
  const [transactionDetails, setTransactionDetails] = useState(initialTransactionDetails)
  const [canSubmit, setCanSubmit] = useState(false)
  const [step, setStep] = useState(1)
  const [currency, setCurrency] = useState("")
  const scrollRef = useRef(null)

  const Navigate = useNavigate()

  const getPageData = async () => {
    setShowLoading(true)
    await listBeneficiary()
      .then((v) => {
        const data = v.data.data
        setBeneficiaryList(
          data
            .filter((benef) => benef?.beneficiaire !== "mobile")
            .map((b) => ({
              label: b.name,
              value: b.beneficiaire,
            })),
        )
        setShowLoading(false)
      })
      .catch((err) => {
        ErrorManager(err, Navigate)
        setShowLoading(false)
      })
  }

  const handleBeneficiaryChange = (e) => {
    const value = e.target.value
    setTransactionDetails((prevState) => {
      return {
        ...prevState,
        beneficiary_account: value,
      }
    })
    if (value.length === accountNumberLength) {
      findAccountByAccountId(value)
        .then((v) => {
          const data = v.data.data
          setTransactionDetails((prevState) => {
            return {
              ...prevState,
              beneficiary_name: data.clientName,
              beneficiary_email: "info@gmail.com",
            }
          })
        })
        .catch((err) => {
          if (err.response.status === 422) {
            setErrorMsg({
              amount: err.response.data.message,
            })
          } else {
            ErrorManager(err, Navigate)
          }
          setCanSubmit(false)
        })
    } else {
      setTransactionDetails((prevState) => {
        return {
          ...prevState,
          beneficiary_name: "",
          beneficiary_email: "",
        }
      })
    }
  }

  const handleBeneficiarySelected = (e) => {
    if (e) {
      setTransactionDetails((prevState) => {
        return {
          ...prevState,
          beneficiary_account: e.value,
          beneficiary_name: e.label,
        }
      })
    } else {
      setTransactionDetails((prevState) => {
        return {
          ...prevState,
          beneficiary_account: "",
          beneficiary_name: "",
        }
      })
    }
  }

  useEffect(() => {
    getPageData()
  }, [])

  useEffect(() => {
    setTransactionDetails((prevState) => {
      return {
        ...prevState,
        accountID: selectAccount,
        accountType: (props.customerAccounts.find((v) => v.value === selectAccount) ?? {}).label,
      }
    })
  }, [selectAccount, props.customerAccounts])

  useEffect(() => {
    const t = transactionDetails
    const value = t.accountID !== "" && t.memo !== "" && t.beneficiary_account !== "" && t.amount > 0 && !showLoading
    setCanSubmit(value)
  }, [transactionDetails, showLoading])

  useEffect(() => {
    if (scrollRef.current) {
      const topPosition = scrollRef.current.getBoundingClientRect().top + window.scrollY - 200
      window.scrollTo({ top: topPosition, behavior: "smooth" })
    }
  }, [step])

  const changeBeneficiaryOption = (option) => {
    setTransactionDetails((prevState) => {
      return {
        ...prevState,
        beneficiary_account: "",
        beneficiary_name: "",
      }
    })
    setBeneficiaryOption(option)
  }

  const handleInputChange = (e) => {
    setTransactionDetails((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      }
    })
  }

  const executeInternalTransfer = async (securityQuestion) => {
    setShowLoading(true)
    try {
      try {
        const res = await makeAccountTransfer(transactionDetails, securityQuestion)
        const data = res.data.data
        data.handleError = handleError

        setStatus(data.status)
        setModalMessage(data.message)
        setVerifyOtp(true)
        setOtpVerificationCallBack(data)
      } catch (err) {
        if (err.response.status === 422) {
          setErrorMsg({
            err: err.response.data.message,
          })
        } else {
          throw err
        }
      }
    } finally {
      setShowLoading(false)
    }
  }

  const handleError = (err) => {
    if (err.response.status === 422 || err.response.status === 404) {
      setErrorMsg({
        err: err.response.data.message,
      })
      setVerifyOtp(false)
      setOtpVerificationCallBack(undefined)
    }
  }

  const cancelBtn = () => {
    setErrorMsg({})
    setStep(1)
  }

  const handleValidate = async (e) => {
    e.preventDefault()
    setShowLoading(true)
    await getTransactionBilling({
      amount: transactionDetails.amount,
      account: transactionDetails.accountID,
      typeOp: props.TYPEOP,
    })
      .then((v) => {
        setCurrency(v.data.data.currency)
        setTransactionDetails((prevState) => {
          return {
            ...prevState,
            fee_amount: v.data.data.retAmount,
            tax: v.data.data.mntTva,
          }
        })
        setStep(2)
      })
      .catch((err) => {
        ErrorManager(err, Navigate)
      })
      .finally(() => {
        setShowLoading(false)
      })
  }

  const handleConfirmOperation = (e) => {
    setVerifyCallback(executeInternalTransfer)
    setVerifySecureQuestions(true)
  }

  return (
    <div ref={scrollRef} className="row ">
      <div style={{ minHeight: "35rem" }} className="col-xl-4 pt-1">
        <div>
          <h4 className="fw-bold">{t("acc_trns_title")}</h4>
        </div>

        {step === 1 ? (
          <form method="POST" onSubmit={handleValidate} className="border-custom p-3">
            <div className="col-xl-12 ">
              <FormInput
                data={props.customerAccounts}
                value={selectAccount}
                onchange={(e) => setSelectedAccount(e ? e.value : "")}
                label={t("acc_to_db")}
                required={true}
                name="accountId"
                type="select"
              />
            </div>

            {beneficiaryOption === "LIST" ? (
              <div className="col-xl-12 ">
                <FormInput
                  data={beneficiaryList}
                  value={transactionDetails.beneficiary_account}
                  onchange={handleBeneficiarySelected}
                  label={t("acc_to_cr")}
                  required={true}
                  name="beneficiary_account"
                  type="select"
                />
                <p style={{ marginTop: "-0.8rem" }}>
                  {t("or")}{" "}
                  <Link className="text-primary" onClick={(e) => changeBeneficiaryOption("CUSTOM")} to={"#"}>
                    {t("enter_acc")}
                  </Link>
                </p>
              </div>
            ) : beneficiaryOption === "CUSTOM" ? (
              <>
                <div className="col-xl-12 ">
                  <FormInput
                    value={transactionDetails.beneficiary_account}
                    onchange={(event) => handleBeneficiaryChange(event)}
                    label={t("benficiary_acc")}
                    required={true}
                    name="beneficiary_account"
                    type="number"
                    errorMsg={errorMsg.accountId}
                  />
                </div>

                <div className="col-xl-12 ">
                  <FormInput value={transactionDetails.beneficiary_name} label={t("benficiary_name")} required={true} name="beneficiary_name" type="text" />
                  <p style={{ marginTop: "-0.8rem" }}>
                    {t("or")}{" "}
                    <Link className="text-primary" onClick={(e) => changeBeneficiaryOption("LIST")} to={"#"}>
                      {t("select_benefry")}
                    </Link>
                  </p>
                </div>
              </>
            ) : (
              ""
            )}

            <div className="col-xl-12 ">
              <FormInput
                value={transactionDetails.amount}
                onchange={handleInputChange}
                label={t("amount")}
                required={true}
                name="amount"
                type="number"
                errorMsg={errorMsg.amount}
              />
            </div>
            <div className="col-xl-12 ">
              <FormInput value={transactionDetails.memo} onchange={handleInputChange} label={t("popos_of_trnfs")} required={true} name="memo" type="text" />
            </div>
            <div className="pt-5">
              <button className="btn btn-primary btn-sm" disabled={!canSubmit || showLoading} type="submit">
                {t("proceed_button")}
              </button>
            </div>
          </form>
        ) : step === 2 ? (
          <div className="border-custom p-3">
            <span>{t("you_are_abt_to")}</span>
            {errorMsg.err && <p className="mt-3 text-center text-white fs-bold p-2 bg-danger">{errorMsg.err}</p>}

            <div className="mt-5 d-flex justify-content-between">
              <h5>{t("account_number")}</h5>
              <h5>{transactionDetails.accountID}</h5>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("acc_type_title")}</h5>
              <h5> {transactionDetails.accountType} </h5>
            </div>
            <hr />
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("beneficiary_name")}</h5>
              <h5> {transactionDetails.beneficiary_name} </h5>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("beficial_acc_no")}</h5>
              <h5> {transactionDetails.beneficiary_account} </h5>
            </div>
            <hr />
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("trnxs_amt")}</h5>
              <h5> {formatAmount(transactionDetails.amount, currency)} </h5>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("trx_fee")}</h5>
              <h5> {formatAmount(transactionDetails.fee_amount, currency)} </h5>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("value_added_task")}</h5>
              <h5>{formatAmount(transactionDetails.tax, currency)}</h5>
            </div>
            <div className="pt-3">
              <button className="btn btn-outline-primary btn-sm me-3" onClick={cancelBtn} type="button">
                {t("cancel_button")}
              </button>
              <button className="btn btn-primary btn-sm" onClick={handleConfirmOperation} type="button" disabled={!canSubmit}>
                {t("proceed_button")}
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="col-xl-2"></div>
      <div className="col-xl-4">
        <div className="card pb-0 border-0">
          <div className="card-body rounded-0">
            <div className="media me-2 pb-5 d-flex justify-content-center">
              <img width="100" height="100" src={Benefit} alt="benefit" />
            </div>
            <div style={{ height: "300px" }} id="DZ_W_Todo3" className="widget-media dz-scroll height270  ps--active-y">
              <ul className="timeline">
                <li>
                  <p className="pl-5 fs-5 d-flex justify-content-center text-center">{t("bank_transfer_info")}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <ModalAfterOperation modalMessage={modalMessage} status={status} setModalMessage={setModalMessage} />
    </div>
  )
}

const InternalTransfer = (props) => {
  const { t } = useTranslation()
  const scrollRef = useRef(null)
  const Navigate = useNavigate()

  const [optionAccounts, setOptionAccounts] = useState([])
  const [internalBeneficiaryList, setInternalBeneficiaryList] = useState([])
  const [selectAccount, setSelectedAccount] = useState("")
  const [modalMessage, setModalMessage] = useState("")
  const [status, setStatus] = useState("")
  const [errorMsg, setErrorMsg] = useState({})
  const { setShowLoading, showLoading, setVerifySecureQuestions, setVerifyCallback, setVerifyOtp, setOtpVerificationCallBack } = useContext(ThemeContext)
  const [transactionDetails, setTransactionDetails] = useState(initialTransactionDetails)
  const [canSubmit, setCanSubmit] = useState(false)
  const [step, setStep] = useState(1)
  const [currency, setCurrency] = useState("")

  const handleBeneficiarySelected = (e) => {
    if (e) {
      setTransactionDetails((prevState) => {
        return {
          ...prevState,
          beneficiary_account: e.value,
          beneficiary_name: e.label,
        }
      })
      setOptionAccounts(props.customerAccounts.filter((v) => v.value !== e.value))
    } else {
      setTransactionDetails((prevState) => {
        return {
          ...prevState,
          beneficiary_account: "",
          beneficiary_name: "",
        }
      })
      setOptionAccounts(props.customerAccounts)
    }
  }

  useEffect(() => {
    setOptionAccounts(props.customerAccounts)
    setInternalBeneficiaryList(props.customerAccounts.filter((v) => v.value !== props.user.primaryAccount))
  }, [props])

  useEffect(() => {
    setTransactionDetails((prevState) => {
      return {
        ...prevState,
        accountID: selectAccount,
        accountType: (optionAccounts.find((v) => v.value === selectAccount) ?? {}).type,
      }
    })
    setInternalBeneficiaryList(props.customerAccounts.filter((v) => v.value !== selectAccount))
  }, [selectAccount, optionAccounts])

  useEffect(() => {
    const t = transactionDetails
    const value = t.accountID !== "" && t.memo !== "" && t.beneficiary_account !== "" && t.amount > 0 && !showLoading
    setCanSubmit(value)
  }, [transactionDetails, showLoading])

  useEffect(() => {
    if (scrollRef.current) {
      const topPosition = scrollRef.current.getBoundingClientRect().top + window.scrollY - 200
      window.scrollTo({ top: topPosition, behavior: "smooth" })
    }
  }, [step])

  const handleInputChange = (e) => {
    setTransactionDetails((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      }
    })
  }

  const executeInternalTransfer = async (securityQuestion) => {
    setShowLoading(true)
    try {
      try {
        const res = await makeAccountTransfer(transactionDetails, securityQuestion)
        const data = res.data.data
        data.handleError = handleError

        setStatus(data.status)
        setModalMessage(data.message)
        setVerifyOtp(true)
        setOtpVerificationCallBack(data)
      } catch (err) {
        if (err.response.status === 422) {
          setErrorMsg({
            err: err.response.data.message,
          })
        } else {
          throw err
        }
      }
    } finally {
      setShowLoading(false)
    }
  }

  const handleError = (err) => {
    if (err.response.status === 422 || err.response.status === 404) {
      setErrorMsg({
        err: err.response.data.message,
      })
      setVerifyOtp(false)
      setOtpVerificationCallBack(undefined)
    }
  }

  const handleValidate = async (e) => {
    e.preventDefault()
    setShowLoading(true)
    await getTransactionBilling({
      amount: transactionDetails.amount,
      account: transactionDetails.accountID,
      typeOp: props.TYPEOP,
    })
      .then((v) => {
        setCurrency(v.data.data.currency)
        setTransactionDetails((prevState) => {
          return {
            ...prevState,
            fee_amount: v.data.data.retAmount,
            tax: v.data.data.mntTva,
          }
        })
        setStep(2)
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setErrorMsg({
            amount: err.response.data.message,
          })
        } else {
          ErrorManager(err, Navigate)
        }
      })
      .finally(() => {
        setShowLoading(false)
      })
  }

  const cancelBtn = () => {
    setErrorMsg({})
    setStep(1)
  }

  const handleConfirmOperation = (e) => {
    setVerifyCallback(executeInternalTransfer)
    setVerifySecureQuestions(true)
  }

  return (
    <div ref={scrollRef} className="row ">
      <div style={{ minHeight: "35rem" }} className="col-xl-4 pt-1">
        <div>
          <h4 className="fw-bold">{t("acc_trns_title")}</h4>
        </div>

        {step === 1 ? (
          <form method="POST" onSubmit={handleValidate} className="border-custom p-3">
            <div className="col-xl-12 ">
              <FormInput
                data={optionAccounts}
                value={selectAccount}
                onchange={(e) => setSelectedAccount(e ? e.value : "")}
                label={t("acc_to_db")}
                required={true}
                name="accountId"
                type="select"
              />
            </div>

            <div className="col-xl-12 ">
              <FormInput
                data={internalBeneficiaryList}
                value={transactionDetails.beneficiary_account}
                onchange={handleBeneficiarySelected}
                label={t("acc_to_cr")}
                required={true}
                name="beneficiary_account"
                type="select"
              />
            </div>
            <div className="col-xl-12 ">
              <FormInput
                value={transactionDetails.amount}
                onchange={handleInputChange}
                label={t("amount")}
                required={true}
                name="amount"
                type="number"
                errorMsg={errorMsg.amount}
              />
            </div>
            <div className="col-xl-12 ">
              <FormInput value={transactionDetails.memo} onchange={handleInputChange} label={t("popos_of_trnfs")} required={true} name="memo" type="text" />
            </div>
            <div className="pt-5">
              <button className="btn btn-primary btn-sm" disabled={!canSubmit} type="submit">
                {t("proceed_button")}
              </button>
            </div>
          </form>
        ) : step === 2 ? (
          <div className="border-custom p-3">
            <span>{t("you_are_abt_to")}</span>
            {errorMsg.err && <p className="mt-3 text-center text-white fs-bold p-2 bg-danger">{errorMsg.err}</p>}

            <div className="mt-5 d-flex justify-content-between">
              <h5>{t("user_acc_type_no")} </h5>
              <h5>{transactionDetails.accountID}</h5>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <h5> {t("acc_type_title")}</h5>
              <h5> {transactionDetails.accountType} </h5>
            </div>
            <hr />
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("beneficiary_name")}</h5>
              <h5> {transactionDetails.beneficiary_name} </h5>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("beneficiary_acc")}</h5>
              <h5> {transactionDetails.beneficiary_account} </h5>
            </div>
            <hr />
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("amount")}</h5>
              <h5>
                {formatAmount(transactionDetails.amount, currency)} {}{" "}
              </h5>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("trx_fee")}</h5>
              <h5>{formatAmount(transactionDetails.fee_amount, currency)} </h5>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("value_added_task")}</h5>
              <h5>{formatAmount(transactionDetails.tax, currency)}</h5>
            </div>
            <div className="pt-3">
              <button className="btn btn-outline-primary btn-sm me-3" onClick={cancelBtn} type="button">
                {t("cancel_button")}
              </button>
              <button className="btn btn-primary btn-sm" onClick={handleConfirmOperation} type="button" disabled={showLoading}>
                {t("proceed_button")}
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="col-xl-2"></div>
      <div className="col-xl-4">
        <div className="card pb-0 border-0">
          <div className="card-body rounded-0">
            <div className="media me-2 pb-5 d-flex justify-content-center">
              <img width="100" height="100" src={Benefit} alt="benefit" />
            </div>
            <div style={{ height: "300px" }} id="DZ_W_Todo3" className="widget-media dz-scroll height270  ps--active-y">
              <ul className="timeline">
                <li>
                  <p className="pl-5 fs-5 d-flex justify-content-center text-center">{t("internal_transfer_info")}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ModalAfterOperation modalMessage={modalMessage} status={status} setModalMessage={setModalMessage} />
    </div>
  )
}

const TransfersPage = (props) => {
  const { t } = useTranslation()
  const { setShowLoading } = useContext(ThemeContext)
  const [customerAccounts, setCustomerAccounts] = useState([])
  const [displayTab, setDisplayTab] = useState("")

  const Navigate = useNavigate()

  const getPageData = async () => {
    setShowLoading(true)
    return getCustomerAccounts()
      .then((v) => {
        const data = v.data.data
        setCustomerAccounts(
          data.map((act) => ({
            value: act.accountID,
            label: `${act.accountID} -${act.accountName}`,
            type: act.accountName,
          })),
        )
        return data
      })
      .catch((err) => {
        ErrorManager(err, Navigate)
      })
      .finally(() => {
        setShowLoading(false)
      })
  }

  useEffect(() => {
    setDisplayTab("bank-transfer")
    getPageData().then(() => setShowLoading(false))
  }, [])

  const swapTab = (e) => {
    // if (step === 1) {
    //     setTransactionDetails(prevState => {
    //         return {
    //             ...prevState,
    //             beneficiary_account: "",
    //             beneficiary_name: ""
    //         }
    //     })
    setDisplayTab(e)
    // }
  }

  return (
    <div className="container-fluid">
      <h4 className="fw-bold">{displayTab}</h4>
      <div className="card bg-white border-0 shadow">
        <div className="card-body">
          <div className="custom-tab-1 bg-white">
            <Tab.Container activeKey={displayTab} defaultActiveKey={`bank-transfer`}>
              <Nav as="ul" className="nav-tabs">
                <Nav.Item onClick={(e) => swapTab("bank-transfer")} as="li" key="bank-transfer-nav">
                  <Nav.Link eventKey="bank-transfer">{t("bank-transfer")}</Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={(e) => swapTab("internal-transfer")} as="li" key="internal-transfer-nav">
                  <Nav.Link eventKey="internal-transfer">{t("internal-transfer")}</Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content className="pt-4">
                <Tab.Pane eventKey="bank-transfer" key={`bank-transfer`}>
                  <BankTransfer user={props.user} customerAccounts={customerAccounts} TYPEOP="OACTRF" />
                </Tab.Pane>

                <Tab.Pane eventKey="internal-transfer" key={`internal-transfer`}>
                  <InternalTransfer user={props.user} customerAccounts={customerAccounts} TYPEOP="OACTRF" />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.userDao,
  }
}
export default connect(mapStateToProps)(TransfersPage)
