/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react"
import Benefit from "../../../common/images/svg/benefit.svg"
import { getConfigurations, listSecurityQuestions, setSecurityQuestions, updateVerificationQuestions } from "../../services/clientAuthService"
import { FormInput } from "../../../common/jsx/components/FormInput"
import { ThemeContext } from "../../../context/ThemeContext"
import { useNavigate } from "react-router-dom"
import { connect, useDispatch } from "react-redux"
import { Logout } from "../../../common/services"
import ErrorManager from "../../errors"
import { baseUrlClient } from "../../../common/constant"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import * as Spinners from "react-loader-spinner"
import * as RemixIcon from "react-icons/ri"

const SecurityQuestions = (props) => {
  const { t } = useTranslation()
  const colRef = useRef(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { showLoading, setShowLoading, setVerifySecureQuestions, setVerifyCallback } = useContext(ThemeContext)
  const [questCount, setQuestCount] = useState(0)
  const [questions, setQuestions] = useState([])
  const [filteredQuestions, setFilteredQuestions] = useState([])
  const [inputGroups, setInputGroups] = useState([])
  const [questionPairs, setQuestionPairs] = useState([])
  const [instConfig, setInstConfig] = useState({})
  const [canSubmit, setCanSubmit] = useState(true)
  const [colHeight, setColHeight] = useState(0)
  const [answerVisibility, setAnswerVisibility] = useState({})

  const getPageData = () => {
    setShowLoading(true)
    return listSecurityQuestions()
      .then((v) => {
        const data = v.data.data
        const options = data.map((q) => ({
          label: q.question,
          value: q.id,
        }))
        setQuestions(options)
        setFilteredQuestions(options)
        return options
      })
      .catch((err) => {
        ErrorManager(err, navigate)
      })
      .finally(() => {
        setShowLoading(false)
      })
  }

  useEffect(() => {
    setShowLoading(true)
    if (colRef.current) {
      setColHeight(colRef.current.clientHeight)
    }
    getConfigurations()
      .then((v) => {
        const data = v.data.data
        setInstConfig(data)
        setQuestCount(data.minSecurityQuest)
        getPageData()
          .then((v) => {
            for (let i = 1; i <= data.minSecurityQuest; i++) {
              inputGroups.push(i)
              setInputGroups(inputGroups)

              questionPairs.push({
                id: i,
                questionId: 0,
                answer: "",
              })
              setQuestionPairs(questionPairs)
            }
          })
          .catch((err) => {
            ErrorManager(err, navigate)
          })
          .finally(() => {
            setShowLoading(false)
          })
      })
      .catch((err) => {
        ErrorManager(err, navigate)
      })
      .finally(() => {
        setShowLoading(false)
      })
  }, [])

  const addQuestion = () => {
    const index = questCount + 1
    if (index <= instConfig.maxSecurityQuest) {
      setQuestCount(index)
      inputGroups.push(index)
      questionPairs.push({
        id: index,
        questionId: 0,
        answer: "",
      })
      setQuestionPairs(questionPairs)
      checkFormValidity()
    } else {
    }
  }

  const removeQuestion = () => {
    const index = questCount
    if (index > instConfig.minSecurityQuest) {
      setQuestCount(index - 1)
      inputGroups.pop()
      questionPairs.pop()
      setQuestionPairs(questionPairs)
      // filterQuestions()
      checkFormValidity()
    } else {
    }
  }

  const answerChange = (e, id) => {
    const updatedPairs = questionPairs.map((pair) => (pair.id === id ? { ...pair, answer: e.target.value } : pair))
    setQuestionPairs(updatedPairs)
    checkFormValidity()
  }

  const questionChange = (e, id) => {
    const updatedPairs = questionPairs.map((pair) => (pair.id === id ? { ...pair, questionId: e ? e.value : null } : pair))
    setQuestionPairs(updatedPairs)
    filterQuestions(updatedPairs)
    checkFormValidity()
  }

  const checkFormValidity = () => {
    const allAnswersNotEmpty = questionPairs.every((pair) => pair.answer.trim() !== "")
    const allQuestionsSelected = questionPairs.every((pair) => pair.questionId !== null && pair.questionId !== 0)

    const isDisabled = !allAnswersNotEmpty || !allQuestionsSelected
    setCanSubmit(isDisabled)
  }

  const filterQuestions = (pairs) => {
    const selectedIds = pairs?.map((v) => v.questionId)
    const availableQuestions = questions?.filter((v) => !selectedIds.includes(v.value))
    setFilteredQuestions(availableQuestions) // Met à jour les questions filtrées
    return availableQuestions
  }

  const setVerificationQuestion = () => {
    setShowLoading(true)
    return setSecurityQuestions(questionPairs)
      .then((res) => {
        if (props.userDto.securityQuestionCounts === 0) {
          dispatch(Logout(navigate, baseUrlClient))
        } else {
          navigate("/home")
        }
      })
      .finally(() => {
        setShowLoading(false)
      })
  }

  const executeUpdateVerificationQuestion = (securityQuestion) => {
    setShowLoading(true)
    return (
      updateVerificationQuestions(questionPairs, securityQuestion)
        .then((res) => {
          navigate("/home")
          toast.success(t("security_save"), { position: "top-right" })
        })
        // .catch((err) => {

        //     ErrorManager(err, navigate)
        // })
        .finally(() => {
          setShowLoading(false)
        })
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (props.userDto.securityQuestionCounts > 0) {
      setVerifyCallback(executeUpdateVerificationQuestion)
      setVerifySecureQuestions(true)
    } else {
      setVerificationQuestion()
    }
  }

  const toggleAnswerVisibility = (id) => {
    setAnswerVisibility((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  return (
    <div className="container-fluid">
      <h4 className="fw-bold">{t("security_questions_title")}</h4>
      <div className="card bg-white border-0 shadow card-2-custom">
        <div className="card-body">
          <div className="row ">
            <div ref={colRef} className="col-xl-8" key={"security_questions"}>
              {showLoading ? (
                <div className="d-flex align-items-center justify-content-center" style={{ height: colHeight + "px" }}>
                  <Spinners.TailSpin height="70" width="70" ariaLabel="tail-spin-loading" radius="4" color="#000" />
                </div>
              ) : (
                <form onSubmit={handleSubmit} className={`border-custom p-3 h-100 ${inputGroups.length <= 0 && "d-flex justify-content-center align-items-center"}`}>
                  {inputGroups.length > 0 ? (
                    inputGroups.map((el, index) => {
                      return (
                        <div className="row" key={"group" + index}>
                          <div className="col-xl-6" key={`question_${el}`}>
                            <FormInput
                              onchange={(e) => questionChange(e, el)}
                              classes={"group" + el}
                              key={`question_input_${el}`}
                              label={t("security_question") + " " + el}
                              name={"security_question-" + el}
                              type="select"
                              data={filteredQuestions}
                            />
                          </div>

                          <div className="col-xl-6 form-group position-relative" key={`answer_${el}`}>
                            <FormInput
                              onchange={(e) => answerChange(e, el)}
                              classes={`group${el} ${answerVisibility[el] ? "" : "masked-input"}`}
                              key={`answer_input_${el}`}
                              label={t("security_answer") + el}
                              name={"security_answer-" + el}
                              type="text"
                            />
                            {questionPairs[index]?.answer !== "" && (
                              <RemixIcon.RiEyeLine
                                className="position-absolute"
                                style={{
                                  right: "25px",
                                  top: "40px",
                                  cursor: "pointer",
                                }}
                                onClick={() => toggleAnswerVisibility(el)}
                              />
                            )}
                            {answerVisibility[el] && (
                              <RemixIcon.RiEyeOffLine
                                className="position-absolute"
                                style={{
                                  right: "25px",
                                  top: "40px",
                                  cursor: "pointer",
                                }}
                                onClick={() => toggleAnswerVisibility(el)}
                              />
                            )}
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div>Not security question found</div>
                  )}
                  {inputGroups.length > 0 && (
                    <div className="d-flex justify-content-between text-end">
                      <div className=" mt-3 ">
                        <button className="btn btn-primary btn-sm me-3" disabled={showLoading || canSubmit}>
                          {t("save_button")}
                        </button>
                        {/* <button className='btn btn-outline-primary btn-sm'>Cancel</button> */}
                      </div>
                      <div className="">
                        {questCount > instConfig.minSecurityQuest ? (
                          <button type="button" onClick={removeQuestion} className="btn btn-danger btn-sm me-2">
                            {t("remove_button")}
                          </button>
                        ) : (
                          ""
                        )}

                        {questCount < instConfig.maxSecurityQuest ? (
                          <button type="button" disabled={showLoading} onClick={addQuestion} className="btn btn-primary d-flex btn-sm ">
                            {t("add_button")}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                </form>
              )}
            </div>

            <div className="col-xl-4">
              <div className="card-body border-0">
                <div className="media me-2 pb-5 d-flex justify-content-center">
                  <img width="100" height="100" src={Benefit} alt="benefit" />
                </div>
                <div style={{ height: "300px" }} className="widget-media dz-scroll height270  ps--active-y">
                  <div className="text-center">
                    <h3>{t("security_questions_title")}</h3>
                  </div>
                  <ul>
                    <li key="desc_1" className="pb-2">
                      <strong>{t("sec_quest_cmd")}</strong>
                    </li>
                    <li key="desc_2">{t("sec_quest_educate")}</li>
                    <h4 className="pt-3 pb-1">{t("change_pass_force")}</h4>
                    <li key="desc_3" className="pb-2">
                      {t("instruction_one")}
                    </li>
                    <li key="desc_4">{t("instruction_two")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    userDto: state.auth.userDao,
  }
}
export default connect(mapStateToProps)(SecurityQuestions)
