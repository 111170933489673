/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useContext } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  clientDetails,
  listAllSubscriptions,
  listDevice,
  resetDivice,
  resetDivices,
  resetSecurityQuestion,
  updateClientEmail,
  updateClientStatus,
} from "../../services/subscriptionService"
import SubscriptionCanvas from "../../components/SubscriptionCanvas"
import MainPageTitle from "../../../common/jsx/layouts/MainPagetitle"
import { ThemeContext } from "../../../context/ThemeContext"
import { DataTablePaginate } from "../../../common/jsx/components/DataTablePaginate"
import SelectOptionFilter from "../../../common/jsx/components/SelectOptionFilter"
import SearchInputFilter from "../../../common/jsx/components/SearchInputFilter"
import { filterType, sortOption } from "../../../data/optionFilter"
import ErrorManager from "../../../appClient/errors"
import { t } from "i18next"
import * as RemixIcon from "react-icons/ri"
import { Modal } from "react-bootstrap"
import { toast } from "react-toastify"
import { format } from "date-fns"
import { enUS } from "date-fns/locale"
import swal from "sweetalert"
import { substringText } from "../../../utils/utilsSubstring"

const CustomerSubscriptions = () => {
  const { setShowLoading, setTableLoading } = useContext(ThemeContext)
  const subscription = useRef()
  const navigate = useNavigate()

  const [tableData, setTableData] = useState([])
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [size] = useState(10)
  const [totalElements, setTotalElements] = useState(0)
  const [type, setType] = useState("")
  const [status, setStatus] = useState("ACTIVE")
  const [order, setOrder] = useState("asc")
  const [filter, setFilter] = useState("clientName")
  const [search, setSearch] = useState("")
  const [chooseStatus, setChooseStatus] = useState("")
  const [reason, setReason] = useState("")

  const [editSecurityInfo, setEditSecurityInfo] = useState(false)
  const [resetDevise, setResetDevise] = useState(false)
  const [detailsUser, setDetailsUser] = useState({})
  const [deviceData, setDeviceData] = useState([])
  const [isEditingEmail, setIsEditingEmail] = useState(false)
  const [editedEmail, setEditedEmail] = useState("")
  const [activeDevice, setActiveDevice] = useState(null)

  // GET TYPE VALUE
  const handleTypeChange = (e) => {
    setType(e.target.value)
  }

  // GET STATUS VALUE
  const handleStatusChange = (e) => {
    setStatus(e.target.value)
  }

  // GET ORDER VALUE
  const handleOrderChange = (e) => {
    setOrder(e.target.value)
  }

  // GET FILTER VALUE
  const handleFilterChange = (e) => {
    setFilter(e.target.value)
  }

  // GET SEARCH VALUE
  const handleSearchChange = (e) => {
    setSearch(e.target.value)
  }

  const handleCancelButton = () => {
    setEditSecurityInfo(false)
    setResetDevise(false)
    setDeviceData([])
    setDetailsUser({})
    setReason("")
  }

  const handleEditEmail = () => {
    setIsEditingEmail(true)
    setEditedEmail(detailsUser.email)
  }

  const handleCancelEdit = () => {
    setIsEditingEmail(false)
    setEditedEmail(detailsUser.email)
  }

  const tableHeader = [
    { label: t("client_matricule") },
    { label: t("login") },
    { label: t("primary_account") },
    { label: t("client_name") },
    { label: t("phone_number") },
    { label: t("email") },
    { label: t("subscription_package") },
    { label: t("subscription_date") },
    { value: "status", label: t("status") },
    { label: t("actions") },
  ]

  const getUserInfo = (matricule) => {
    setShowLoading(true)
    clientDetails(matricule)
      .then((res) => {
        setDetailsUser(res.data.data)
      })
      .catch((err) => {
        ErrorManager(err, navigate)
      })
      .finally(() => {
        setShowLoading(false)
      })
  }

  const getDeviceInfo = (uuid) => {
    setShowLoading(true)
    listDevice(uuid)
      .then((res) => {
        console.log("ress", res)

        setDeviceData(res.data.data)
      })
      .catch((err) => {
        ErrorManager(err, navigate)
      })
      .finally(() => {
        setShowLoading(false)
      })
  }

  const handleOpenDetailsUser = (matricule) => {
    setEditSecurityInfo(true)
    getUserInfo(matricule)
  }

  const handleShowDevice = (uuid, name) => {
    setResetDevise(true)
    getDeviceInfo(uuid)
    setDetailsUser({
      uuid,
      name,
    })
  }

  // FILTER SELECT TAG DATA
  const filterOptions = [
    { value: "clientMatricule", label: t("client_matricule") },
    { value: "userLogin", label: t("login") },
    { value: "primaryAccount", label: t("primary_account") },
    { value: "clientName", label: t("client_name") },
    { value: "phoneNumber", label: t("phone_number") },
    { value: "email", label: t("email") },
  ]

  const statusType = [
    { value: "ACTIVE", label: t("active") },
    { value: "", label: t("all") },
    { value: "SUSPENDED", label: t("suspended") },
    { value: "BLOCKED", label: t("blocked") },
  ]

  const handleStatusChoose = (e) => {
    setChooseStatus(e.target.value)
  }

  const handleStatusUpdate = () => {
    setShowLoading(true)
    updateClientStatus(detailsUser.clientMatricule, chooseStatus, reason)
      .then((res) => {
        toast.success(t("status_updated_successfully"), {
          position: "top-right",
        })
        setEditSecurityInfo(false)
        getPageData()
      })
      .catch((err) => {
        if (err.response) {
          ErrorManager(err, navigate)
        } else {
          ErrorManager(err, navigate)
        }
      })
      .finally(() => {
        setShowLoading(false)
        setReason("")
      })
  }

  const getPageData = () => {
    setShowLoading(true)
    setTableLoading(true)
    listAllSubscriptions(order, filter, search, type, status, page, size)
      .then((res) => {
        setTotalElements(res.data?.data?.totalElements)
        setTotalPages(res.data?.data?.totalPages)
        setTableData(
          res.data?.data?.data.map((d) => [
            d.clientMatricule,
            d.userLogin,
            d.primaryAccount,
            <div className="text-nowrap">{d.name}</div>,
            d.phoneNumber,
            d.email,
            "",
            <div className="text-nowrap">
              {format(new Date(d.subscriptionDate), "EEEE dd MMMM yyyy", {
                locale: enUS,
              })}
            </div>,
            <button className={`badge p-1 ${d.status === "ACTIVE" ? "bg-success" : d.status === "PENDING" ? "bg-warning" : "bg-danger"}`}>{d.status}</button>,
            <div className="d-flex gap-1">
              <button onClick={() => handleOpenDetailsUser(d.clientMatricule)} type="button" className="d-flex gap-1 align-items-center btn btn-sm btn-primary  p-1">
                <RemixIcon.RiEyeLine size={15} />
              </button>
              <button onClick={() => handleShowDevice(d.uuid, d.name)} type="button" className="d-flex gap-1 align-items-center btn btn-sm btn-outline-primary p-1">
                <RemixIcon.RiDeviceLine size={15} />
              </button>
            </div>,
          ]),
        )
      })
      .catch((err) => {
        if (err.response) {
          ErrorManager(err, navigate)
        } else {
          ErrorManager(err, navigate)
        }
      })
      .finally(() => {
        setShowLoading(false)
        setTableLoading(false)
      })
  }

  useEffect(() => {
    setPage(0)
    getPageData()
    setShowLoading(false)
  }, [order, filter, search, type, status, size])

  useEffect(() => {
    getPageData()
    setShowLoading(false)
  }, [page])

  const statusOptions = ["ACTIVE", "INACTIVE", "SUSPENDED", "BLOCKED"]
  const filteredStatusOptions = statusOptions.filter((status) => status !== detailsUser.status)

  const handleResetSecurity = () => {
    swal({
      title: t("reset_security"),
      text: `${t("are_you_sure_you_want_to_reset_security")}?`,
      icon: "warning",
      buttons: [t("button_cancel"), t("button_reset")],
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        resetSecurityQuestion(detailsUser.clientMatricule)
          .then((res) => swal(`${"reset"}!`, t("security_has_been_reset"), t("success")))
          .catch((err) => {
            ErrorManager(err, navigate)
          })
      }
    })
  }

  const handleResetDevice = (uuid, deviceId) => {
    resetDivice(uuid, deviceId)
      .then((res) => {
        toast.success(t("device_has_been_deleted"), {
          position: "top-right",
        })
        getDeviceInfo(uuid)
      })
      .catch((err) => {
        if (err.response) {
          ErrorManager(err, navigate)
        } else {
          ErrorManager(err, navigate)
        }
      })
      .finally(() => {
        setShowLoading(false)
      })
  }

  const handleResetDevices = () => {
    resetDivices(detailsUser.uuid)
      .then((res) => {
        toast.success(t("devices_has_been_deleted"), {
          position: "top-right",
        })
        getDeviceInfo()
      })
      .catch((err) => {
        if (err.response) {
          ErrorManager(err, navigate)
        } else {
          ErrorManager(err, navigate)
        }
      })
      .finally(() => {
        setShowLoading(false)
      })
  }

  const handleSaveEmail = () => {
    if (editedEmail !== detailsUser.email) {
      setShowLoading(true)
      const body = {
        status: detailsUser.status,
        email: editedEmail,
      }

      updateClientEmail(detailsUser.clientMatricule, body)
        .then((res) => {
          toast.success(t("email_updated_successfully"), {
            position: "top-right",
          })
          setEditSecurityInfo(false)
          getPageData()
        })
        .catch((err) => {
          if (err.response) {
            ErrorManager(err, navigate)
          } else {
            ErrorManager(err, navigate)
          }
        })
        .finally(() => {
          setShowLoading(false)
        })
    }
  }

  return (
    <>
      <MainPageTitle mainTitle="Subscriptions" pageTitle={"Subscribers"} parentTitle={"Subscriptions"} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card rounded-0 p-3 shadow">
              <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center p-0 mb-3">
                    <h4 className="heading mb-0">{t("subscriptions")}</h4>
                    <div>
                      <Link to={"#"} className="btn btn-primary btn-sm" data-bs-toggle="offcanvas" onClick={() => subscription.current.showSubscriptionModal()}>
                        + {t("add_subscription")}
                      </Link>{" "}
                    </div>
                  </div>
                  <div className="option-filter-table">
                    <SelectOptionFilter label={t("status")} id="status" name={status} value={status} onChange={handleStatusChange} options={statusType} />

                    <SelectOptionFilter label={t("customer_type")} id="type" name={type} value={type} onChange={handleTypeChange} options={filterType} />

                    <SelectOptionFilter label={t("sort_by")} id="sort" name={order} value={order} onChange={handleOrderChange} options={sortOption} />

                    <SelectOptionFilter label={t("filter_by")} id="filter" name={filter} value={filter} onChange={handleFilterChange} options={filterOptions} />

                    <SearchInputFilter filter={filter} placeholder={t("type_here")} ariaLabel="Search" value={search} onChange={handleSearchChange} />
                  </div>
                  <div>
                    <DataTablePaginate
                      headersTitle={tableHeader}
                      tableData={tableData}
                      totalPages={totalPages}
                      currentPage={page}
                      size={size}
                      totalElements={totalElements}
                      setPage={setPage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="fade" size="md" show={editSecurityInfo} centered>
        <Modal.Header style={{ height: "3rem" }} className="bg-primary border-0">
          <Modal.Title className="text-white">{t("details_user")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white pt-0">
          <div className="row d-flex flex-column gap-2">
            <div className="col-12 p-2">
              <span className="fw-bold"> {t("client_name")}</span>: {detailsUser.name}
            </div>
            <div className="col-12 p-2">
              <span className="fw-bold">{t("phone_number")} : </span>
              {detailsUser.phoneNumber ? detailsUser.phoneNumber : "---"}
            </div>
            <div className="col-12 p-2 d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center gap-1">
                <span className="fw-bold d-flex">{t("email")}:</span>
                {isEditingEmail ? (
                  // Mode d'édition : afficher un champ input
                  <input type="email" value={editedEmail} onChange={(e) => setEditedEmail(e.target.value)} className="form-control p-1 p h-75" />
                ) : detailsUser.email ? (
                  substringText(detailsUser.email, 30)
                ) : (
                  "---"
                )}
              </div>
              {/* <div className="d-flex gap-2">
                {isEditingEmail ? (
                  <>
                    {editedEmail !== detailsUser.email && (
                      <button className="btn btn-success btn-sm" onClick={handleSaveEmail}>
                        <RemixIcon.RiCheckLine size={15} />
                        {t("save_button")}
                      </button>
                    )}

                    <button className="btn btn-danger btn-sm" onClick={handleCancelEdit}>
                      <RemixIcon.RiCloseLine size={15} />
                      {editedEmail === detailsUser.email && t("cancel_button")}
                    </button>
                  </>
                ) : (
                  <button className="btn btn-outline-primary btn-sm d-flex gap-1 align-items-center" onClick={handleEditEmail}>
                    <RemixIcon.RiEditBoxLine size={15} />
                    {t("edit_button")}
                  </button>
                )}
              </div> */}
            </div>
            <div className="col-12 d-flex justify-content-between align-items-center border p-2 fw-bold">
              {t("reset_security")}
              <button className="btn btn-warning btn-sm d-flex gap-1" onClick={handleResetSecurity}>
                <RemixIcon.RiRestartLine size={15} />
                {t("reset_button")}
              </button>
            </div>
            <div className="col-12 border p-2">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <span className="fw-bold">{t("status")}: </span> {detailsUser.status}
                </div>
                <select name="status" id="status" className="p-1 border bg-l" onChange={handleStatusChoose} value={chooseStatus}>
                  <option value="">---</option>
                  {filteredStatusOptions.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </div>
              <div className="d-flex justify-content-between mt-1 hidden">
                <div hidden={chooseStatus === ""}>
                  <label htmlFor="reason" className="me-2">
                    {t("reason")}: <strong className="text-danger">*</strong>
                  </label>
                  <input type="text" className="border p-1" required value={reason} onChange={(e) => setReason(e.target.value)} />
                </div>
                <button className="btn btn-sm btn-primary d-flex gap-1" disabled={reason === ""} onClick={handleStatusUpdate}>
                  <RemixIcon.RiSendPlaneLine size={15} />
                  {t("change")}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 bg-white">
          <button className="btn btn-primary btn-sm" onClick={handleCancelButton}>
            {t("cancel_button")}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal className="fade" size="md" show={resetDevise} centered>
        <Modal.Header style={{ height: "3rem" }} className="bg-primary border-0 px-2">
          <Modal.Title className="text-white">{t("details_device")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white p-2">
          <div className="col-12">
            <span className="fw-bold"> {t("client_name")}</span>: {detailsUser.name}
          </div>

          <div className="mt-3 border p-1 shadow">
            <div className="d-flex justify-content-between align-items-center">
              <span className="fw-bold mb-2"> {t("list_of_device")}</span>
              {deviceData.length > 0 && (
                <button className="btn btn-danger btn-sm d-flex gap-1 px-1" onClick={handleResetDevices}>
                  <RemixIcon.RiRestartLine size={15} />
                  {t("delete_all")}
                </button>
              )}
            </div>
            <div className="mt-3 d-flex flex-column gap-1 position-relative">
              {deviceData.map((device) => (
                <div className="border p-1 d-flex align-items-center justify-content-between">
                  <div className={`d-flex gap-2`}>
                    {device?.deviceType === "MOBILE" ? (
                      <RemixIcon.RiSmartphoneLine size={20} />
                    ) : device?.deviceType === "TABLET" ? (
                      <RemixIcon.RiTabletLine size={20} />
                    ) : (
                      <RemixIcon.RiMacbookLine size={20} />
                    )}
                    <span>{device?.deviceName}</span>
                    <span className="badge bg-success" title={t("name_device")}>
                      {device?.osName}
                    </span>
                    {/* Détails supplémentaires affichés dynamiquement */}
                    {activeDevice === device.uuid && (
                      <div
                        className="d-flex gap-2 justify-content-between align-items-center position-absolute bg-primary text-white w-100 border shadow p-2"
                        style={{ top: "-50px", left: "0" }}
                      >
                        <span className="badg bg-primar" title={t("os_version")}>
                          Version: {device?.osVersion}
                        </span>
                        <span className="" title={t("last_login_time")}>
                          {format(device?.lastLoginTime, "yyyy-MM-dd HH:MM:SS")}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="d-flex gap-1">
                    <button
                      className="btn btn-outline-primary btn-sm d-flex gap-1 px-1"
                      onClick={() => (activeDevice === device.uuid ? setActiveDevice(null) : setActiveDevice(device.uuid))}
                      onMouseEnter={() => setActiveDevice(device.uuid)}
                      onMouseLeave={() => setActiveDevice(null)}
                    >
                      More
                    </button>
                    <button className="btn btn-danger btn-sm d-flex gap-1 px-1" onClick={() => handleResetDevice(device?.user?.uuid, device?.uuid)}>
                      <RemixIcon.RiRestartLine size={15} />
                      {t("delete_button")}
                    </button>
                  </div>
                </div>
              ))}
              {deviceData.length <= 0 && <div className="text-center">{t("not_device_found")}</div>}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="borde bg-white p-1">
          <button className="btn btn-outline-primary btn-sm" onClick={handleCancelButton}>
            {t("cancel_button")}
          </button>
        </Modal.Footer>
      </Modal>

      <SubscriptionCanvas ref={subscription} getPageData={getPageData} Title={t("add_subscription")} />
    </>
  )
}

export default CustomerSubscriptions
