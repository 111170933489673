import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { store } from "./common/store"
import ThemeContext from "./context/ThemeContext"
import "./i18n"
// import AppAdmin from "./appAdmin";
// import AppCustomer from "./appClient";
import "@fontsource/poppins"
import "../src/common/css/style.css"
import "../src/common/jsx/pages/index.css"
import "../src/common/jsx/pages/custom.css"
import "../src/common/css/App.css"
import "react-toastify/dist/ReactToastify.css"
import "react-checkbox-tree/lib/react-checkbox-tree.css"
import { ToastContainer } from "react-toastify"
import App from "./App"
import MetaData from "./MetaData"

const isProduction = true

if (isProduction) {
  console.log = function () {}
  console.warn = function () {}
  console.error = function () {}
  console.info = function () {}
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <ThemeContext>
        <MetaData />
        <ToastContainer />
        <App />
      </ThemeContext>
    </BrowserRouter>
  </Provider>,
  // </React.StrictMode>
)
