/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react"
import { deleteBeneficiary, listBeneficiary } from "../../services/acountServices"
import { DataTable } from "../../../common/jsx/components/DataTable"
import { ThemeContext } from "../../../context/ThemeContext"
import Benefit from "../../../common/images/svg/benefit.svg"
import swal from "sweetalert"
import { NavLink, useNavigate } from "react-router-dom"
import * as RemixIcon from "react-icons/ri"
import ErrorManager from "../../errors"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"

const Beneficiary = () => {
  const { t } = useTranslation()

  const [beneficiaryList, setBeneficiaryList] = useState([])
  const { setShowLoading, setVerifySecureQuestions, setVerifyCallback } = useContext(ThemeContext)

  const Navigate = useNavigate()

  const benefTableHeaders = [{ label: t("beneficiary_name") }, { label: t("account_number") }, { label: t("action") }]

  let selectedId

  const executeDeleteBeneficiary = (securityQuestions) => {
    setShowLoading(true)
    return deleteBeneficiary(selectedId, securityQuestions)
      .then((v) => {
        toast.success("Success")
        getPageData()
      })
      .finally(() => {
        setShowLoading(false)
      })
  }

  const beneficiaryDelete = (id) => {
    setShowLoading(true)
    return swal({
      title: t("are_u_sure"),
      text: t("delete_alert"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          selectedId = id
          setShowLoading(true)
          setVerifyCallback(executeDeleteBeneficiary)
          setVerifySecureQuestions(true)
          getPageData()
        }
      })
      .catch((err) => {
        ErrorManager(err, Navigate)
      })
      .finally(() => {
        setShowLoading(false)
      })
  }

  const getPageData = () => {
    setShowLoading(true)
    return listBeneficiary()
      .then((v) => {
        const data = v.data.data
        setBeneficiaryList(
          data.map((b) => [
            b.name,
            b.beneficiaire,
            <div className="action-button">
              <button type="button" onClick={() => beneficiaryDelete(b.id)} className="btn btn-icon-xxs text-danger">
                <RemixIcon.RiDeleteBin2Line />
              </button>
            </div>,
          ]),
        )
      })
      .catch((err) => {
        ErrorManager(err)
      })
      .finally(() => {
        setShowLoading(false)
      })
  }

  useEffect(() => {
    getPageData()
  }, [])

  return (
    <div className="container-fluid">
      <h4 className="fw-bold">{t("beneficiaries_page_title")} </h4>
      <div className="row top-50">
        <div className="col-xl-12">
          <div className="card bg-white border-0 shadow">
            <div className="card-body">
              <div className="row ">
                <div className="col-xl-8 pb-2">
                  <DataTable headersTitle={benefTableHeaders} tableData={beneficiaryList} />
                </div>
                <div className="col-xl-4">
                  <div className="card pb-0 border-0 ">
                    <div className="border-0">
                      <div className="card-body">
                        <div className="media me-2 pb-5 d-flex justify-content-center">
                          <img width="100" height="100" src={Benefit} alt="benefit" />
                        </div>
                        <div style={{ height: "300px" }} id="DZ_W_Todo3" className="widget-media dz-scroll height270  ps--active-y">
                          <h2 className="mb-3 fw-bold text-center">{t("note")}</h2>
                          <p className="pl-5 fs-5 text-center">{t("add_beneficry_promt")}</p>
                          <div className="d-flex justify-content-center mt-5 ">
                            <NavLink to={"/add-beneficiary"} className="btn btn-sm btn-outline-primary">
                              {t("add_beneficry_btn")}
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Beneficiary
