import React, { useContext, useEffect, useState } from "react"
import { FormInput } from "../../../common/jsx/components/FormInput"
import { ThemeContext } from "../../../context/ThemeContext"
import { listInstitutionConfig, updateInstitutionConfig } from "../../services/configurationService"
import MainPageTitle from "../../../common/jsx/layouts/MainPagetitle"
import ErrorManager from "../../../appClient/errors"
import { useNavigate } from "react-router-dom"
import { baseUrlAdmin } from "../../../common/constant"
import { t } from "i18next"
import { toast } from "react-toastify"
import { Tab, Tabs } from "react-bootstrap"

const InstitutionConfig = () => {
  const navigate = useNavigate()
  const { setShowLoading, showLoading } = useContext(ThemeContext)
  const [tabData, setTabData] = useState([])
  const [formData, setFormData] = useState({})
  const [initialFormData, setInitialFormData] = useState({})
  const [activeTab, setActiveTab] = useState("")

  const inputTemplate = [
    { label: t("institution_name"), name: "institutionName", value: "" },
    { label: t("institution_short_name"), name: "institutionShortName", value: "" },
    { label: t("max_security_questions"), name: "maxSecurityQuest", value: 0 },
    { label: t("min_security_questions"), name: "minSecurityQuest", value: 0 },
    { label: t("otp_min_before_expire"), name: "otpMinBeforeExpire", value: 0 },
    { label: t("institution_email"), name: "institutionEmail", value: "" },
    { label: t("email_password"), name: "emailPassword", value: "" },
    { label: t("return_URL"), name: "returnUrl", value: "" },
    { label: t("email_no_reply"), name: "emailNoReply", value: "" },
    { label: t("host"), name: "host", value: "" },
    { label: t("port"), name: "port", value: 0 },
    { label: t("verify_quest_number"), name: "verifyQuestNumber", value: 0 },
    { label: t("max_verify_attempt"), name: "maxVerifyAttempt", value: 0 },
    { label: t("default_package"), name: "defaultPackage", value: "" },
    {
      label: t("subscription_method"),
      name: "subMethod",
      value: "AUTOMATIC",
      type: "select",
      options: [
        { label: t("manual"), value: "MANUAL" },
        { label: t("automatic"), value: "AUTOMATIC" },
      ],
    },
    {
      label: t("verification_reset_timer"),
      name: "verificationResetTimer",
      value: 0,
    },
    {
      label: t("verification_prompt"),
      name: "questConfig",
      value: "MANUAL",
      type: "select",
      options: [
        { label: t("manual"), value: "MANUAL" },
        { label: t("automatic"), value: "AUTO" },
      ],
    },
    { label: t("town"), name: "town", value: "" },
    { label: t("phone"), name: "phone", value: "" },
    { label: t("fee"), name: "payerFeePercentage", value: 0 },
    {
      label: t("transaction_otp"),
      name: "trnasOtp",
      value: false,
      type: "select",
      options: [
        { label: "True", value: true },
        { label: "False", value: false },
      ],
    },
    {
      label: t("beneficiary_otp"),
      name: "benefOtp",
      value: false,
      type: "select",
      options: [
        { label: "True", value: true },
        { label: "False", value: false },
      ],
    },
  ]

  const getPageData = async () => {
    setShowLoading(true)
    try {
      const v = await listInstitutionConfig()
      const data = v.data.data
      const tabs = Object.entries(data)
        .filter(([key, values]) => values !== null)
        .map(([key, values]) => {
          const tabFormData = {}
          inputTemplate.forEach((field) => {
            tabFormData[field.name] = values[field.name] || field.value
          })
          return { key, values: tabFormData }
        })

      setTabData(tabs)
      setFormData(tabs.reduce((acc, tab) => ({ ...acc, [tab.key]: tab.values }), {}))
      setInitialFormData(tabs.reduce((acc, tab) => ({ ...acc, [tab.key]: tab.values }), {}))
      setActiveTab(tabs[0]?.key)
    } catch (err) {
      ErrorManager(err, navigate)
    } finally {
      setShowLoading(false)
    }
  }

  useEffect(() => {
    getPageData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (event, tabKey) => {
    const { name, value } = event.target
    setFormData((prevState) => ({
      ...prevState,
      [tabKey]: {
        ...prevState[tabKey],
        [name]: value,
      },
    }))
  }

  const handleSelectChange = (event, tabKey, name) => {
    setFormData((prevState) => ({
      ...prevState,
      [tabKey]: {
        ...prevState[tabKey],
        [name]: event.value,
      },
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setShowLoading(true)
    const updatedData = formData[activeTab]

    // const updatedData = formData[activeTab]
    updateInstitutionConfig(activeTab.toLocaleUpperCase(), updatedData)
      .then(() => {
        toast.success(t("changes_saved_successfully"), {
          position: "top-right",
        })
        getPageData()
      })
      .catch((err) => {
        ErrorManager(err, navigate)
      })
      .finally(() => {
        setShowLoading(false)
      })
  }

  const hasChanges = () => {
    return JSON.stringify(formData) !== JSON.stringify(initialFormData)
  }

  return (
    <>
      <MainPageTitle mainTitle="Dashboard" pageTitle={t("general_config")} parentTitle={"Configuration"} />
      <div className="container-fluid">
        <div className="row p-3 pt-0">
          <div className="card rounded-0 shadow card-bx m-b30 m-">
            <div className="card-header">
              <h6 className="title">{t("general_configurations")}</h6>
            </div>
            <Tabs defaultActiveKey={tabData[0]?.key} onSelect={(selectedTab) => setActiveTab(selectedTab)} id="institution-config-tabs">
              {tabData.map((tab) => (
                <Tab eventKey={tab.key} title={tab.key} key={tab.key}>
                  <form type="PUT" action={`${baseUrlAdmin}/instConfig/update`} onSubmit={handleSubmit} className="profile-form">
                    <div className="card-body">
                      <div className="row">
                        {inputTemplate.map((item, ind) => (
                          <div className="col-xl-3 col-sm-6" key={ind}>
                            <FormInput
                              classes="custom-form-input"
                              onchange={item.type === "select" ? (e) => handleSelectChange(e, tab.key, item.name) : (e) => handleChange(e, tab.key)}
                              type={item.type ?? "text"}
                              data={item.options}
                              name={item.name}
                              value={formData[tab.key]?.[item.name]}
                              label={item.label}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="card-footer">
                      <button className="btn btn-primary btn-sm" disabled={!hasChanges() || showLoading}>
                        {t("update_button")}
                      </button>
                    </div>
                  </form>
                </Tab>
              ))}
            </Tabs>
          </div>
        </div>
      </div>
    </>
  )
}

export default InstitutionConfig
