import React, { useContext, useEffect, useRef, useState } from "react"
import { getCustomerAccounts, listBeneficiary } from "../../services/acountServices"
import { connect } from "react-redux"
import { ThemeContext } from "../../../context/ThemeContext"
import { getNameByNumber, getTransactionBilling, InitiateTransaction, makeDiaporaDeposit } from "../../services/mobileBankService"
import { Nav, Spinner, Tab } from "react-bootstrap"
import { useQuery } from "../../../common/services"
import { FormInput } from "../../../common/jsx/components/FormInput"
import ErrorManager from "../../errors"
import ModalAfterOperation from "../../components/ModalAfterOperation"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { formatAmount } from "../../../utils/utilsFormattedAmount"
import Benefit from "../../../common/images/svg/benefit.svg"
import { substringText } from "../../../utils/utilsSubstring"

const mobileMoneyWithdrawalOperator = [
  {
    label: "Orange Money(Cameroon)",
    value: "OMDEPO",
  },
  {
    label: "MTN Momo(Cameroon)",
    value: "MOMODE",
  },
]

let b2wTransactionDetails = {
  accountID: null,
  accountType: "",
  beneficiary_account: "",
  beneficiary_name: "",
  operator: "",
  TypeOp: "",
  amount: 0,
  memo: "",
  fee_amount: 0,
  tax: 0,
}

let w2bTransactionDetails = {
  accountID: null,
  accountType: "",
  beneficiary_account: "",
  operator: "",
  TypeOp: "",
  amount: 0,
  memo: "",
  fee_amount: 0,
  tax: 0,
}

const WalletToBank = (props) => {
  const scrollRef = useRef(null)
  const { t } = useTranslation()
  const { showLoading, setShowLoading, setVerifySecureQuestions, setVerifyCallback, setWalletOpLoading, setWalletOp } = useContext(ThemeContext)
  const [transactionDetails, setTransactionDetails] = useState(w2bTransactionDetails)
  const [canContinue, setCanContinue] = useState(false)
  const [nameNumberLoader, setNameNumberLoader] = useState(false)
  const [step, setStep] = useState(1)
  const [errorMsg, setErrorMsg] = useState({})
  const [currency, setCurrency] = useState("")
  const [operator, setOperator] = useState("")
  const [nameNumber, setNameNumber] = useState("")

  const Navigate = useNavigate()

  const mobileMoneyDepositOperator = [
    {
      label: "Orange Money(Cameroon)",
      value: "OMRETR",
    },
    {
      label: "MTN Momo(Cameroon)",
      value: "MTNRET",
    },
    // {
    //   label: t("others_deposits"),
    //   value: "OMRETR",
    // },
  ]

  const handleAccountChange = (e) => {
    setTransactionDetails((prevState) => {
      return {
        ...prevState,
        accountID: e ? e.value : null,
        accountType: e ? e.label : null,
      }
    })
  }

  const handleOperatorChange = (e) => {
    setTransactionDetails((prevState) => {
      return {
        ...prevState,
        operator: e ? e.label : null,
        TypeOp: e ? e.value : null,
      }
    })
  }

  const executeInitiateDeposit = async (securityQuestions) => {
    const body = {
      accountId: transactionDetails.accountID,
      phoneNumber: transactionDetails.beneficiary_account,
      description: transactionDetails.memo,
      amount: transactionDetails.amount,
      typeOp: transactionDetails.TypeOp,
    }
    const v = await InitiateTransaction(body, securityQuestions, "deposit")
    const data = v.data.data
    body.paymentGatewaysUuid = data.paymentGatewaysUuid
    body.walletToBank = true
    setWalletOp(body)
    setWalletOpLoading(true)
  }

  const openNewWindow = (url) => {
    var screenWidth = window.screen.width
    var screenHeight = window.screen.height

    var leftPosition = (screenWidth - 1000) / 2
    var topPosition = (screenHeight - 600) / 2

    window.open(url, "_blank", "width=1000,height=800,left=" + leftPosition + ",top=" + topPosition)
  }

  const executeInitiateOtherDeposit = async (securityQuestions) => {
    setShowLoading(true)
    const body = {
      accountID: transactionDetails.accountID,
      currency: "XAF",
      amount: transactionDetails.amount,
      memo: transactionDetails.memo,
      fee_amount: transactionDetails.fee_amount,
      tax: transactionDetails.tax,
    }

    try {
      try {
        const res = await makeDiaporaDeposit(body, securityQuestions)
        Navigate("/home")
        openNewWindow(res.data.data.data.links.paymentAuthUrl)
      } catch (err) {
        if (err.response.status === 422) {
          setErrorMsg({
            err: err.response.data.message,
          })
        } else {
          throw err
        }
      }
    } finally {
      setShowLoading(false)
    }
  }

  const handleConfirmOperation = (e) => {
    setVerifyCallback(executeInitiateDeposit)
    setVerifySecureQuestions(true)
  }

  const handleConfirmOther = (e) => {
    e.preventDefault()
    setVerifyCallback(executeInitiateOtherDeposit)
    setVerifySecureQuestions(true)
  }

  const handleInputChange = (e) => {
    setTransactionDetails((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      }
    })
  }

  useEffect(() => {
    if (scrollRef.current) {
      const topPosition = scrollRef.current.getBoundingClientRect().top + window.scrollY - 200
      window.scrollTo({ top: topPosition, behavior: "smooth" })
    }
  }, [step])

  useEffect(() => {
    const t = transactionDetails
    const value =
      t.accountID !== "" &&
      t.memo !== "" &&
      ((t.TypeOp === "OACTRF" && t.beneficiary_account === "") || (t.TypeOp !== "OACTRF" && t.beneficiary_account !== "")) &&
      t.amount > 0 &&
      !showLoading
    setCanContinue(value)
  }, [transactionDetails, showLoading])

  const verifyOperator = (phoneNumber) => {
    const mtnPrefixes = ["650", "651", "652", "653", "654", "67", "680", "681", "682", "683"]
    const orangePrefixes = ["655", "656", "657", "658", "6590", "6591", "6592", "6593", "6594", "659", "69"]

    const digitsOnly = phoneNumber.replace(/\D/g, "")
    let cleanedNumber = digitsOnly
    if (digitsOnly.startsWith("237")) {
      cleanedNumber = digitsOnly.slice(3)
    }

    if (cleanedNumber.length === 9) {
      if (mtnPrefixes.some((prefix) => cleanedNumber.startsWith(prefix))) {
        setErrorMsg({ phoneNumber: "" })
        return "MTNRET"
      } else if (orangePrefixes.some((prefix) => cleanedNumber.startsWith(prefix))) {
        setErrorMsg({ phoneNumber: "" })
        return "OMRETR"
      } else {
        setErrorMsg({ phoneNumber: t("uncateg_optor") })
      }
    } else if (cleanedNumber.length > 9) {
      setErrorMsg({ phoneNumber: t("number_incorrect") })
    } else {
      setErrorMsg({ phoneNumber: "" })
      setNameNumber("")
    }
  }

  useEffect(() => {
    const operator = verifyOperator(transactionDetails.beneficiary_account)

    setOperator(operator)
    if ((operator === "OMRETR" || operator === "MTNRET") && transactionDetails.beneficiary_account) {
      setNameNumberLoader(true)
      getNameByNumber(transactionDetails.beneficiary_account)
        .then((res) => {
          const data = res.data?.data?.data
          if (data.status !== "FAILED") {
            setNameNumber(data.verifiedName)
          } else {
            setErrorMsg({
              ...errorMsg,
              phoneNumber: t("number_not_found"),
            })
          }
        })
        // .catch((err) => console.error("err", err))
        .finally(() => setNameNumberLoader(false))
    }
  }, [transactionDetails.beneficiary_account])

  const handleValidate = async (e) => {
    e.preventDefault()
    setShowLoading(true)

    // Check the correspondance
    if (transactionDetails.TypeOp !== "OACTRF") {
      if (operator) {
        if (operator !== transactionDetails.TypeOp) {
          let nameOperator = ""
          if (operator === "OMRETR") {
            nameOperator = "MTN"
          } else {
            nameOperator = "ORANGE"
          }

          setErrorMsg({
            phoneNumber: `The entered phone number does not correspond to ${nameOperator}.`,
          })

          setShowLoading(false)
          return
        } else {
          await getTransactionBilling({
            amount: transactionDetails.amount,
            account: transactionDetails.accountID,
            typeOp: transactionDetails.TypeOp,
          })
            .then((v) => {
              setCurrency(v.data.data.currency)
              setTransactionDetails((prevState) => {
                return {
                  ...prevState,
                  fee_amount: v.data.data.retAmount,
                  tax: v.data.data.mntTva,
                }
              })
              setStep(2)
            })
            .catch((err) => {
              if (err.response.status === 422) {
                setErrorMsg({
                  amount: err.response.data.message,
                })
              } else {
                ErrorManager(err, Navigate)
              }
            })
            .finally(() => {
              setShowLoading(false)
            })
        }
      }
    } else {
      await getTransactionBilling({
        amount: transactionDetails.amount,
        account: transactionDetails.accountID,
        currency: "XAF",
        typeOp: transactionDetails.TypeOp,
      })
        .then((v) => {
          setTransactionDetails((prevState) => {
            return {
              ...prevState,
              fee_amount: v.data.data.retAmount,
              tax: v.data.data.mntTva,
            }
          })
          setCurrency(v.data.data.currency)
          setStep(3)
        })
        .catch((err) => {
          if (err.response.status === 422) {
            setErrorMsg({
              amount: err.response.data.message,
            })
          } else {
            ErrorManager(err, Navigate)
          }
        })
        .finally(() => {
          setShowLoading(false)
        })
    }
  }

  const cancelButton = () => {
    setErrorMsg({})
    setStep(1)
  }

  return (
    <div ref={scrollRef} className="row ">
      <div className="col-xl-4 pb-3 pt-2">
        {/* <div>
                    <h4 className='fw-bold'>{t('wallet_to_bank')}</h4>
                </div> */}

        {step === 1 ? (
          <form method="POST" onSubmit={handleValidate} className="border-custom p-3">
            <div className="row ">
              <div className="col-xl-12">
                <FormInput
                  onchange={(event) => handleAccountChange(event)}
                  data={props.optionAccounts}
                  value={transactionDetails.accountID}
                  label={t("user_acc_select")}
                  required={true}
                  name="accountId"
                  type="select"
                />
              </div>
              <div className="col-xl-12 ">
                <FormInput
                  value={transactionDetails.TypeOp}
                  onchange={handleOperatorChange}
                  data={mobileMoneyDepositOperator}
                  label={t("select_operator")}
                  required={true}
                  name="typeOp"
                  type="select"
                />
              </div>
              {transactionDetails.TypeOp && transactionDetails.TypeOp !== "OACTRF" && (
                <div className="col-xl-12 position-relative">
                  <FormInput
                    value={transactionDetails.beneficiary_account}
                    onchange={handleInputChange}
                    label={t("user_phone")}
                    required={true}
                    name="beneficiary_account"
                    errorMsg={errorMsg.phoneNumber}
                    type="number"
                  />
                  {nameNumberLoader && (
                    <Spinner
                      className="position-absolute text-blue"
                      style={{
                        top: "5px",
                        right: "10px",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  )}
                  {nameNumber && !nameNumberLoader && (
                    <span
                      className="position-absolute bg-blue text-white"
                      style={{
                        top: "18px",
                        right: "10px",
                        fontSize: "12px",
                        padding: "1px 5px",
                      }}
                    >
                      {substringText(nameNumber, 25)}
                    </span>
                  )}
                </div>
              )}
              {(nameNumber || transactionDetails.TypeOp === "OACTRF") && (
                <div className="col-xl-12">
                  <FormInput
                    onchange={handleInputChange}
                    value={transactionDetails.amount}
                    label={t("amount")}
                    required={true}
                    name="amount"
                    type="number"
                    errorMsg={errorMsg.amount}
                  />
                </div>
              )}
              <div className="col-xl-12 ">
                <FormInput onchange={handleInputChange} value={transactionDetails.memo} label={t("popos_of_trnfs")} required={true} name="memo" type="text" />
              </div>
            </div>
            <div>
              <button className="btn btn-primary btn-sm me-3" disabled={!canContinue} type="submit">
                {t("proceed_button")}
              </button>
            </div>
          </form>
        ) : step === 2 ? (
          <div className="border-custom p-3">
            <span>{t("you_are_abt_to")} </span>
            <div className="mt-5 d-flex justify-content-between">
              <h5>{t("account_number")} </h5>
              <h5>{transactionDetails.accountID}</h5>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <h5> {t("acc_type_title")}</h5>
              <h5> {transactionDetails.accountType} </h5>
            </div>
            <hr />
            <div className="mt-1 d-flex justify-content-between">
              <h5> {t("acc_to_db")} </h5>
              <h5> {transactionDetails.beneficiary_account} </h5>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("operator")}</h5>
              <h5> {transactionDetails?.TypeOp === "OMRETR" ? "ORANGE" : "MTN"} CAMEROON LTD </h5>
            </div>
            <hr />
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("amount")} </h5>
              <h5> {formatAmount(transactionDetails.amount, currency)}</h5>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("trx_fee")} </h5>
              <h5>{formatAmount(transactionDetails.fee_amount, currency)}</h5>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("value_added_task")}</h5>
              <h5>{formatAmount(transactionDetails.tax, currency)}</h5>
            </div>
            <div className="pt-3">
              <button className="btn btn-outline-primary btn-sm me-3" onClick={(e) => setStep(1)} type="button">
                {t("cancel_button")}
              </button>
              <button className="btn btn-primary btn-sm" onClick={handleConfirmOperation} type="button" disabled={!canContinue}>
                {t("proceed_button")}
              </button>
            </div>
          </div>
        ) : step === 3 ? (
          <div className="border-custom p-3 h-100">
            <span>{t("you_are_abt_to")} </span>
            {errorMsg.err && <p className="mt-3 text-center text-white fs-bold p-2 bg-danger">{errorMsg.err}</p>}
            <div className="mt-5 d-flex justify-content-between">
              <h5>{t("account_number")} </h5>
              <h5>{transactionDetails.accountID}</h5>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("acc_type_title")}</h5>
              <h5> {transactionDetails.accountType} </h5>
            </div>
            <hr />
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("amount")}</h5>
              <h5>{formatAmount(transactionDetails.amount, currency)}</h5>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("trx_fee")} </h5>
              <h5>{formatAmount(transactionDetails.fee_amount, currency)}</h5>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("value_added_task")}</h5>
              <h5>{formatAmount(transactionDetails.tax, currency)}</h5>
            </div>
            <div className="pt-3">
              <button className="btn btn-outline-primary btn-sm me-3" onClick={cancelButton} type="button">
                {t("cancel_button")}
              </button>
              <button className="btn btn-primary btn-sm" onClick={handleConfirmOther} type="button" disabled={showLoading}>
                {t("proceed_button")}
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="col-xl-1 mb-5"></div>

      <div className="col-xl-7">
        <div className="card border-0 pb-0">
          <div className="card-body border-0 ">
            <div className="media me-2 pb-5 d-flex justify-content-center">
              <img width="100" height="100" src={Benefit} alt="benefit" />
            </div>
            <div style={{ height: "300px" }} id="DZ_W_Todo3" className="widget-media dz-scroll height270  ps--active-y">
              <ul className="timeline">
                <li>
                  <p className="pl-5 fs-5 text-center">{t("wallet_to_bank_info")} </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const BankToWallet = (props) => {
  const { t } = useTranslation()
  const scrollRef = useRef(null)
  const Navigate = useNavigate()

  const { setShowLoading, showLoading, setVerifySecureQuestions, setVerifyCallback, setVerifyOtp, setOtpVerificationCallBack } = useContext(ThemeContext)
  const [transactionDetails, setTransactionDetails] = useState(b2wTransactionDetails)
  const [canContinue, setCanContinue] = useState(false)
  const [step, setStep] = useState(1)
  const [errorMsg, setErrorMsg] = useState({})
  const [modalMessage, setModalMessage] = useState("")
  const status = ""
  const [currency, setCurrency] = useState("")
  const [beneficiaries, setBeneficiaries] = useState([])

  const handleAccountChange = (e) => {
    setTransactionDetails((prevState) => {
      return {
        ...prevState,
        accountID: e ? e.value : null,
        accountType: e ? e.label : null,
      }
    })
  }

  const handleOperatorChange = (e) => {
    setTransactionDetails((prevState) => {
      return {
        ...prevState,
        operator: e ? e.label : null,
        TypeOp: e ? e.value : null,
      }
    })
  }

  const handleBeneficiary = (e) => {
    setTransactionDetails((prevState) => {
      return {
        ...prevState,
        beneficiary_account: e ? e.value : null,
        beneficiary_name: e ? e.label : null,
      }
    })
  }

  console.log("beneficiaries", beneficiaries)

  const formattedBeneficiaries = beneficiaries
    .filter((benef) => benef?.operator === transactionDetails.operator)
    .map((beneficiary) => ({
      label: beneficiary.name,
      value: beneficiary.phoneNumber,
    }))

  const handleInputChange = (e) => {
    setTransactionDetails((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      }
    })
  }

  const executeAccountWithdrawal = async (securityQuestions) => {
    setShowLoading(true)
    const body = {
      accountId: transactionDetails.accountID,
      phoneNumber: transactionDetails.beneficiary_account,
      description: transactionDetails.memo,
      amount: transactionDetails.amount,
      typeOp: transactionDetails.TypeOp,
    }
    try {
      const v = await InitiateTransaction(body, securityQuestions, "withdrawal")
      const data = v.data.data
      data.handleError = handleError
      body.paymentGatewaysUuid = data.paymentGatewaysUuid
      body.walletToBank = false
      sessionStorage.setItem("tr", JSON.stringify(body))
      setVerifyOtp(true)
      setOtpVerificationCallBack(data)
    } finally {
      setShowLoading(false)
    }
  }

  const handleError = (err) => {
    if (err.response.status === 422 || err.response.status === 404) {
      setErrorMsg({
        err: err.response.data.message,
      })
      setVerifyOtp(false)
      setOtpVerificationCallBack(undefined)
    }
  }

  const handleConfirmOperation = (e) => {
    setVerifyCallback(executeAccountWithdrawal)
    setVerifySecureQuestions(true)
  }

  const loadBeneficiaries = () => {
    setShowLoading(true)
    listBeneficiary()
      .then((res) => setBeneficiaries(res.data.data))
      .catch((err) => {
        if (err.response.status === 422) {
          setErrorMsg({
            nameNumber: err.response.data.message,
          })
        } else {
          ErrorManager(err, Navigate)
        }
      })
      .finally(() => {
        setShowLoading(false)
      })
  }

  useEffect(() => {
    loadBeneficiaries()
  }, [])

  useEffect(() => {
    const t = transactionDetails
    const value = t.accountID !== "" && t.memo !== "" && t.beneficiary_account !== "" && t.amount > 0 && !showLoading
    setCanContinue(value)
  }, [transactionDetails, showLoading])

  useEffect(() => {
    if (scrollRef.current) {
      const topPosition = scrollRef.current.getBoundingClientRect().top + window.scrollY - 200
      window.scrollTo({ top: topPosition, behavior: "smooth" })
    }
  }, [step])

  const verifyOperator = (phoneNumber) => {
    const mtnPrefixes = ["650", "651", "652", "653", "654", "67", "680", "681", "682", "683"]
    const orangePrefixes = ["655", "656", "657", "658", "6590", "6591", "6592", "6593", "6594", "659", "69"]

    const digitsOnly = phoneNumber.replace(/\D/g, "")
    let cleanedNumber = digitsOnly
    if (digitsOnly.startsWith("237")) {
      cleanedNumber = digitsOnly.slice(3)
    }

    if (cleanedNumber.length === 9) {
      if (mtnPrefixes.some((prefix) => cleanedNumber.startsWith(prefix))) {
        setErrorMsg({ phoneNumber: "" })
        return "MTNRET"
      } else if (orangePrefixes.some((prefix) => cleanedNumber.startsWith(prefix))) {
        setErrorMsg({ phoneNumber: "" })
        return "OMRETR"
      } else {
        setErrorMsg({ phoneNumber: t("uncateg_optor") })
      }
    } else if (cleanedNumber.length > 9) {
      setErrorMsg({ phoneNumber: t("number_incorrect") })
    } else {
      setErrorMsg({ phoneNumber: "" })
      setTransactionDetails({
        ...transactionDetails,
        beneficiary_name: "",
      })
    }
  }

  const handleValidate = async (e) => {
    e.preventDefault()
    setShowLoading(true)

    await getTransactionBilling({
      amount: transactionDetails.amount,
      account: transactionDetails.accountID,
      typeOp: transactionDetails.TypeOp,
    })
      .then((v) => {
        setCurrency(v.data.data.currency)
        setTransactionDetails((prevState) => {
          return {
            ...prevState,
            fee_amount: v.data.data.retAmount,
            tax: v.data.data.mntTva,
          }
        })
        setStep(2)
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setErrorMsg({
            amount: err.response.data.message,
          })
        } else {
          ErrorManager(err, Navigate)
        }
      })
      .finally(() => {
        setShowLoading(false)
      })
  }

  const cancelButton = () => {
    setErrorMsg({})
    setStep(1)
  }

  console.log("transactionDetails", transactionDetails)

  return (
    <div ref={scrollRef} className="row ">
      <div className="col-xl-4 pb-3 pt-2">
        <div>
          <h4 className="fw-bold">{t("db_bank_to_wallet")}</h4>
        </div>

        {step === 1 ? (
          <form method="POST" onSubmit={handleValidate} className="border-custom p-3">
            <div className="row ">
              <div className="col-xl-12">
                <FormInput
                  onchange={(event) => handleAccountChange(event)}
                  data={props.optionAccounts}
                  value={transactionDetails.accountID}
                  label={t("select_account")}
                  required={true}
                  name="accountId"
                  type="select"
                />
              </div>
              <div className="col-xl-12">
                <FormInput
                  value={transactionDetails.TypeOp}
                  onchange={handleOperatorChange}
                  data={mobileMoneyWithdrawalOperator}
                  label={t("select_operator")}
                  required={true}
                  name="typeOp"
                  type="select"
                />
              </div>
              {transactionDetails.TypeOp && (
                <div className="col-xl-12 position-relative mb-3">
                  <FormInput
                    value={transactionDetails.beneficiary_account}
                    onchange={handleBeneficiary}
                    data={formattedBeneficiaries}
                    label={t("select_beneficiary")}
                    required={true}
                    name="beneficiary_account"
                    type="select"
                    errorMsg={errorMsg.phoneNumber}
                  />
                  <Link to="/account/beneficiary" color="blue" className="text-blue text-decoration-underline position-absolute" style={{ bottom: "-10px" }}>
                    Manage beneficiary
                  </Link>
                </div>
              )}

              {transactionDetails.beneficiary_account && (
                <div className="col-xl-12 ">
                  <FormInput
                    onchange={handleInputChange}
                    value={transactionDetails.amount}
                    label={t("amount")}
                    required={true}
                    name="amount"
                    type="number"
                    errorMsg={errorMsg.amount}
                  />
                </div>
              )}
              {/* <div className="col-xl-12 ">
                <FormInput
                  value={transactionDetails.beneficiary_name}
                  onchange={handleInputChange}
                  label={t("beneficiary_name")}
                  required={false}
                  name="beneficiary_name"
                  type="text"
                />
              </div> */}
              <div className="col-xl-12 ">
                <FormInput onchange={handleInputChange} value={transactionDetails.memo} label={t("popos_of_trnfs")} required={true} name="memo" type="text" />
              </div>
            </div>
            <div>
              <button className="btn btn-primary btn-sm me-3" disabled={!canContinue} type="submit">
                {t("proceed_button")}
              </button>
            </div>
          </form>
        ) : step === 2 ? (
          <div className="border-custom p-3">
            <span>{t("you_are_abt_to")} </span>
            {errorMsg.err && <p className="mt-3 text-center text-white fs-bold p-2 bg-danger">{errorMsg.err}</p>}
            <div className="mt-5 d-flex justify-content-between">
              <h5>{t("account_number")} </h5>
              <h5>{transactionDetails.accountID}</h5>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("acc_type_title")} </h5>
              <h5> {transactionDetails.accountType} </h5>
            </div>
            <hr />
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("beneficiary_name")}</h5>
              <h5> {transactionDetails.beneficiary_name} </h5>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("beneficiary_acc")} </h5>
              <h5> {transactionDetails.beneficiary_account} </h5>
            </div>
            <hr />
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("amount")} </h5>
              <h5> {formatAmount(transactionDetails.amount, currency)}</h5>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("trx_fee")} </h5>
              <h5>{formatAmount(transactionDetails.fee_amount, currency)} </h5>
            </div>
            <div className="mt-1 d-flex justify-content-between">
              <h5>{t("value_added_task")}</h5>
              <h5>{formatAmount(transactionDetails.tax, currency)}</h5>
            </div>
            <div className="pt-3">
              <button className="btn btn-outline-primary btn-sm me-3" onClick={cancelButton} type="button">
                {t("cancel_button")}
              </button>
              <button className="btn btn-primary btn-sm" onClick={handleConfirmOperation} type="button" disabled={showLoading}>
                {t("Proceed_button")}
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="col-xl-1 mb-5"></div>

      <div className="col-xl-7">
        <div className="card border-0 pb-0">
          <div className="card-body border-0 ">
            <div className="media me-2 pb-5 d-flex justify-content-center">
              <img width="100" height="100" src={Benefit} alt="benefit" />
            </div>
            <div style={{ height: "300px" }} id="DZ_W_Todo3" className="widget-media dz-scroll height270  ps--active-y">
              <ul className="timeline">
                <li>
                  <p className="pl-5 fs-5 text-center">{t("bank_to_wallet_info")} </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <ModalAfterOperation modalMessage={modalMessage} status={status} setModalMessage={setModalMessage} />
    </div>
  )
}

const MobileMoneyPage = (props) => {
  const { t } = useTranslation()

  const [optionAccounts, setOptionAccounts] = useState([])
  const Navigate = useNavigate()

  let query = useQuery()
  const { setShowLoading } = useContext(ThemeContext)
  const defaultKey = query.get("view") ?? "account-details"
  const [displayTab, setDisplayTab] = useState(defaultKey)

  const getPageData = async () => {
    setShowLoading(true)
    try {
      try {
        const v = await getCustomerAccounts()
        const data = v.data.data
        setOptionAccounts(
          data.map((act) => ({
            value: act.accountID,
            label: `${act.accountID} -${act.accountName}`,
          })),
        )
        return data
      } catch (err) {
        ErrorManager(err, Navigate)
      }
    } finally {
      setShowLoading(false)
    }
  }

  useEffect(() => {
    setDisplayTab(defaultKey)
  }, [defaultKey])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getPageData().then((v) => {
      setShowLoading(false)
    })
  }, [])
  /* eslint-disable react-hooks/exhaustive-deps */

  // const swapTab = (e) => {
  //     // if (step === 1) {
  //     //     setTransactionDetails(prevState => {
  //     //         return {
  //     //             ...prevState,
  //     //             beneficiary_account: "",
  //     //             beneficiary_name: ""
  //     //         }
  //     //     })
  //     setDisplayTab(e)
  //     // }
  // }

  return (
    <div className="container-fluid">
      <h2 className="card-title mb-3">{t(displayTab)} </h2>
      <div className="row top-50">
        <div className="col-xl-12">
          <div className="card bg-white border-0 shadow">
            <div className="card-body">
              <div className="custom-tab-1 bg-white">
                <Tab.Container activeKey={displayTab} defaultActiveKey={displayTab}>
                  <Nav as="ul" className="nav-tabs">
                    <Nav.Item onClick={(e) => setDisplayTab("wallet-to-bank")} as="li" key="wallet-to-bank-nav">
                      <Nav.Link eventKey="wallet-to-bank">{t("wallet_to_bank")}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={(e) => setDisplayTab("bank-to-wallet")} as="li" key="bank-to-wallet-nav">
                      <Nav.Link eventKey="bank-to-wallet">{t("bank_to_wallet")}</Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Tab.Content className="pt-4">
                    <Tab.Pane eventKey="wallet-to-bank" key={`wallet-to-bank`}>
                      <WalletToBank optionAccounts={optionAccounts} tab="bank-to-wallet" />
                    </Tab.Pane>

                    <Tab.Pane eventKey="bank-to-wallet" key={`bank-to-wallet`}>
                      <BankToWallet optionAccounts={optionAccounts} tab="bank-to-wallet" />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.userDao,
  }
}
export default connect(mapStateToProps)(MobileMoneyPage)
